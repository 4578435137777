import './main.css';
import React from 'react';
import 'animate.css';

function Main() {
const [isActive, setIsActive] = React.useState(0);
console.log(isActive)
  return (
   <div className="main">

   <div className={isActive == 1 ? 'left_none': isActive == 3 ? "left_animation" :'left'}>
   <div className="cheap_container">
   <div className={isActive == 1 ? 'cheap_right_none animate__bounceInLeft animate__animated' : isActive == 3 ? 'cheap_right_none animate__bounceInLeft animate__animated' : 'animate__bounceInLeft animate__animated cheap_right '}>
      <div className='cheap_right_header '>იაფიანი ყუთი</div>
      <div className='cheap_right_desc'>ამ ყუთის შიგთავსის ღირებულება 60-დან 2000 ლარამდე მერყეობს. მათ შორის საგზური, ლეპტოპი და ა.შ</div>
</div>
<div className={isActive == 3 ? "cheap_left " : isActive == 1 ? "cheap_left " : 'cheap_left animate__animated animate__bounceInLeft'}>
      <div className={isActive == 3 ? 'del' : 'cheap_price_cont'}>
      <div className='cheap_price_icon'></div>
      <div className='cheap_price'>69</div>
   </div>
   <div className={isActive == 3 ? 'cheap_box_animated ' : 'cheap_box '}>
   <div className={isActive == 3 ? 'cheap_shadow_animation_in' : isActive == 4 ? 'cheap_shadow_animation_out' : 'cheap_shadow'}>
   {isActive == 3 ? <div className='cheap_back animate__animated animate__bounceInRight' onClick={() => setIsActive(4)}> </div> : null}
   {isActive == 3 ? 
   <div className='cheap_order_container animate__bounceInRight animate__animated'>
      <div className='cheap_order_info'>
         <div className='cheap_order_header '>შეავსე საჩუქრის ადრესატის ინფორმაცია</div>
         <div className='order_list'>
            <div className='cheap_order_line mrg '>
               <div className='cheap_order_line_title'>საჩუქრის ადრესატი</div>
               <div className='cheap_order_line_info rad'>
                  <label className="control rem control--radio">
                     ბიჭი
                     <input type="radio" name="radio"></input>
                     <div className="control__indicator"></div>
                  </label>
                  <label className="control control--radio">
                     გოგო
                     <input type="radio" name="radio"></input>
                     <div className="control__indicator"></div>
                  </label>
               </div>
            </div>
            <div className='cheap_order_line '>
               <div className='cheap_order_line_title'>ვის უგზავნით (სახელი გვარი)</div>
               <div className='cheap_order_line_info'><input placeholder='გიორგი მახარაშვილი' className='cheap_order_input'></input></div>
            </div>
            <div className='cheap_order_line '>
               <div className='cheap_order_line_title'>ქალაქი სად გზავნით</div>
               <div className='cheap_order_line_info'><input placeholder='თბილისი' className='cheap_order_input'></input></div>
            </div>
            <div className='cheap_order_line '>
               <div className='cheap_order_line_title'>მისამართი სად გზავნით</div>
               <div className='cheap_order_line_info'><input placeholder='რუსთაველის N40' className='cheap_order_input'></input></div>
            </div>
            <div className='cheap_order_line '>
               <div className='cheap_order_line_title'>გამგზავნის ტელეფონის ნომერი</div>
               <div className='cheap_order_line_info'><input placeholder='577 21 80 54' className='cheap_order_input'></input></div>
            </div>
            <div className='cheap_order_line '>
               <div className='cheap_order_line_title'>მიმღების ტელეფონის ნომერი</div>
               <div className='cheap_order_line_info'><input placeholder='591 11 54 43' className='cheap_order_input'></input></div>
            </div>
         </div>
      </div>
      <div className='cheap_order_purchase_cont '>
         <div className='ord_cont'>
            <div className='but_price'>ფასი: 69</div>
            <div className='but_cont'>
               <div className='buy_button3' >ყიდვა</div>
            </div>
         </div>
      </div>
   </div>
   : null}

</div>
<div className={isActive == 3 ? 'cheap_icon_animated' : 'cheap_icon'}></div>
</div>
<div className={isActive == 3 ? 'del' : 'buy_button2'} onClick={() => setIsActive(3)}>ყიდვა</div>
</div>
</div>
</div>

<div className={isActive == 1 ? 'right_animation ' : isActive == 3 ? 'right_none' : 'right'}>
<div className='rich_container'>
   <div className={isActive == 1 ? 'rich_left': isActive == 3 ? 'rich_left' : 'rich_left animate__bounceInRight animate__animated'}>
   <div className={isActive == 1 ? 'del' : 'rich_price_cont'}>
   <div className='rich_price_icon'></div>
   <div className='rich_price '>249</div>
</div>
<div className={isActive == 1 ? 'rich_box_animated ' : 'rich_box '}>
<div className={isActive == 1 ? 'rich_shadow_animation_in' : isActive == 2 ? 'rich_shadow_animation_out' : 'rich_shadow'}>
{isActive == 1 ? <div className='rich_back animate__bounceInLeft animate__animated' onClick={() => setIsActive(2)}> </div> : null}
{isActive == 1 ? 
<div className='rich_order_container animate__bounceInLeft animate__animated'>
   <div className='rich_order_info'>
      <div className='rich_order_header'>შეავსე საჩუქრის ადრესატის ინფორმაცია</div>
      <div className='order_list'>
         <div className='rich_order_line mrg'>
            <div className='rich_order_line_title'>საჩუქრის ადრესატი</div>
            <div className='rich_order_line_info rad'>
               <label className="control rem control--radio">
                  ბიჭი
                  <input type="radio" name="radio"></input>
                  <div className="control__indicator"></div>
               </label>
               <label className="control control--radio">
                  გოგო
                  <input type="radio" name="radio"></input>
                  <div className="control__indicator"></div>
               </label>
            </div>
         </div>
         <div className='rich_order_line'>
            <div className='rich_order_line_title'>ვის უგზავნით (სახელი გვარი)</div>
            <div className='rich_order_line_info'><input placeholder='გიორგი მახარაშვილი' className='rich_order_input'></input></div>
         </div>
         <div className='rich_order_line'>
            <div className='rich_order_line_title'>ქალაქი სად გზავნით</div>
            <div className='rich_order_line_info'><input placeholder='თბილისი' className='rich_order_input'></input></div>
         </div>
         <div className='rich_order_line'>
            <div className='rich_order_line_title'>მისამართი სად გზავნით</div>
            <div className='rich_order_line_info'><input placeholder='რუსთაველის N40' className='rich_order_input'></input></div>
         </div>
         <div className='rich_order_line'>
            <div className='rich_order_line_title'>გამგზავნის ტელეფონის ნომერი</div>
            <div className='rich_order_line_info'><input placeholder='577 21 80 54' className='rich_order_input'></input></div>
         </div>
         <div className='rich_order_line'>
            <div className='rich_order_line_title'>მიმღების ტელეფონის ნომერი</div>
            <div className='rich_order_line_info'><input placeholder='591 11 54 43' className='rich_order_input'></input></div>
         </div>
      </div>
   </div>
   <div className='rich_order_purchase_cont'>
      <div className='ord_cont'>
         <div className='but_price'>ფასი: 249</div>
         <div className='but_cont'>
            <div className='buy_button3' >ყიდვა</div>
         </div>
      </div>
   </div>
</div>
: null}

</div>
<div className={isActive == 1 ? 'rich_icon_animated' : 'rich_icon'}></div>
</div>
<div className={isActive == 1 ? 'del' : 'buy_button2 '} onClick={() => setIsActive(1)}>ყიდვა</div>
</div>
<div className={isActive == 1  ? 'del' : isActive == 3 ? "del" : isActive == 4 ? "rich_right animate__bounceInRight animate__animated" : 'rich_right animate__bounceInRight animate__animated'}>
<div className='rich_right_header'>მდიდრების ყუთი</div>
<div className='rich_right_desc'>ამ ყუთის შიგთავსის ღირებულება 60-დან 2000 ლარამდე მერყეობს. მათ შორის საგზური, ლეპტოპი და ა.შ</div>
</div>
</div>
</div>

</div>
  );
}

export default Main;
