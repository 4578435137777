import './App.css';
import Main from './modules/main/main';

function App() {
  return (
    <div className="App">
    <Main/>
    </div>
  );
}

export default App;
